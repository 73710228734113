import ScrollReveal from 'scrollreveal'

import THEME from './theme'

const distance = '5%'
const scale = {
  in: 0.95,
  out: 1.05,
}

const ANIMATION = {
  FADE_IN_TOP: {
    distance,
    origin: 'top',
    opacity: 0,
  },
  FADE_IN_LEFT: {
    distance,
    origin: 'left',
    opacity: 0,
  },
  FADE_IN_RIGHT: {
    distance,
    origin: 'right',
    opacity: 0,
  },
  FADE_IN_BOTTOM: {
    distance,
    origin: 'bottom',
    opacity: 0,
  },
  FADE_IN_ZOOM_IN: {
    scale: scale.in,
    opacity: 0,
  },
  FADE_IN_ZOOM_OUT: {
    scale: scale.out,
    opacity: 0,
  },
  SLIDE_IN_BOTTOM: {
    distance,
    origin: 'bottom',
    opacity: 1,
  },
  SLIDE_IN_RIGHT: {
    distance,
    origin: 'right',
    opacity: 1,
  },
  ZOOM_IN: {
    scale: scale.in,
    opacity: 1,
  },
  ZOOM_OUT: {
    scale: scale.out,
    opacity: 1,
  },
}

export const animate = ScrollReveal({
  duration: THEME.DURATION.LONGER,
  easing: THEME.EASING.EASE_IN_OUT,
  cleanup: true
}).reveal

export default ANIMATION
