const THEME = {
  UNIT: 24,
  BREAKPOINT: {
    XS: 599,
    SM: 959,
    MD: 1279,
  },
  DURATION: {
    SHORTEST: 150,
    MEDIUM: 500,
    LONGER: 1000,
    LONGEST: 2000,
  },
  EASING: {
    EASE_IN_OUT: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
}

export default THEME
