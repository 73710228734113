import Swiper from 'swiper'

import THEME from '../../js/theme'

const initSwiper = () => {
  const elem = document.querySelector('.testimonials__swiper');

  new Swiper(elem, {
    speed: THEME.DURATION.LONGER,
    loop: true,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    navigation: {
      nextEl: elem.nextElementSibling,
      prevEl: elem.nextElementSibling.nextElementSibling,
    },
  })
}

export default function initTestimonials() {
  initSwiper()
}
