import Swiper from 'swiper'

import THEME from '../../js/theme'

const initSwiper = () => {
  new Swiper('.elements-slider__swiper', {
    speed: THEME.DURATION.LONGER,
    spaceBetween: THEME.UNIT / 2,
    slideToClickedSlide: true,

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet(index, className) {
        return '<span class="' + className + '"></span>'
      },
    },

    breakpoints: {
      [THEME.BREAKPOINT.XS]: {
        spaceBetween: THEME.UNIT,
      },
    },
  })
}

export default function initCoffee() {
  initSwiper()
}
