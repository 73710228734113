import SmoothScroll from 'smooth-scroll'

import initHeader, {
  renderMobileNavigation,
  replaceMobileNavigation,
  renderMobileHeaderText,
  replaceMobileHeaderText,
} from '../blocks/header/header'

import initCoffee from '../blocks/elements-slider/elements-slider'
import initInstagram from '../blocks/instagram/instagram'
import initContact from '../blocks/contact/contact'
import initTestimonials from '../blocks/testimonials/testimonials'

import ANIMATION, {animate} from './animation'
import THEME from './theme'

import '../styles/index.scss'

window.THEME = THEME

const breakpoint = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
}

const useMediaQuery = () => {
  const isMobile = () => window.innerWidth <= THEME.BREAKPOINT.XS
  const isTablet = () => window.innerWidth > THEME.BREAKPOINT.XS && window.innerWidth <= THEME.BREAKPOINT.SM
  const isDesktop = () => window.innerWidth > THEME.BREAKPOINT.SM && window.innerWidth <= THEME.BREAKPOINT.MD

  let width = isMobile() ? breakpoint.xs : isTablet() ? breakpoint.sm : isDesktop() ? breakpoint.md : breakpoint.lg

  const initialRender = () => {
    if (width === breakpoint.xs) {
      renderMobileHeaderText()
      renderMobileNavigation()
    } else if (width === breakpoint.sm || width === breakpoint.md) {
      renderMobileNavigation()
    }
  }

  initialRender()
  window.addEventListener('resize', () => {
    if (isMobile() && width !== breakpoint.xs) {
      width = breakpoint.xs
      renderMobileHeaderText()
    } else if (isTablet() && width !== breakpoint.sm) {
      width = breakpoint.sm
      replaceMobileHeaderText()
    } else if (isDesktop() && width !== breakpoint.md) {
      width = breakpoint.md
      renderMobileNavigation()
    } else if (window.innerWidth > THEME.BREAKPOINT.MD && width !== breakpoint.lg) {
      width = breakpoint.lg
      replaceMobileNavigation()
    }
  })
}

const setSmoothScroll = () => {
  new SmoothScroll('a[href*="#"]', {
    speed: THEME.DURATION.LONGER,
    speedAsDuration: true,
  })
}

const animatePartials = () => {
  let animatedGroups = []

  document.querySelectorAll('[data-animated]')
    .forEach((elem, index) => {
      const animationName = elem.getAttribute('data-animated')
                                .toUpperCase().replace(/-/g, '_')
      let animationGroupID = parseInt(elem.getAttribute('data-animated-group'))
      const animationSettings = ANIMATION[animationName];

      if (typeof animationSettings === 'object')
      {
        if (isNaN(animationGroupID))
        {
          animationGroupID = 0
        }

        if (isNaN(animatedGroups[animationGroupID]))
        {
          animatedGroups[animationGroupID] = 0;
        }

        animate(elem, {
          ...animationSettings,
          delay: window.innerWidth <= THEME.BREAKPOINT.XS || animationGroupID === 0
              ? 0 : THEME.DURATION.SHORTEST * animatedGroups[animationGroupID]++,
        })
      }
      else
      {
        throw `No such animation: ${animationName}`
      }
    })
}

const removePreloader = () => {
  const preloader = document.getElementById('preloader')

  preloader.classList.remove('preloader-show')
  setTimeout(() => {
    preloader.remove()
  }, THEME.DURATION.LONGER)
  document.body.style.overflow = 'auto'
}

function init() {
  //useMediaQuery()
  setSmoothScroll()
  animatePartials()

  initHeader()
  initCoffee()
  initTestimonials()
  // initInstagram()
  initContact()

  setTimeout(removePreloader, 0)
}

document.addEventListener('DOMContentLoaded', init)
