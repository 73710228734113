import renderSnackbar from '../../js/snackbar'
import flatpickr from 'flatpickr'

require('flatpickr/dist/themes/dark.css');

const initForm = () => {
  const form = document.querySelector('.contact__form')
  const date = document.querySelector('.form__field--date')
  const time = document.querySelector('.form__field--time')
  const submitButton = form.querySelector('button[type="submit"]')

  flatpickr(date, {
    disableMobile: true,
    dateFormat: 'd/m/Y',
    minDate: 'today'
  })

  flatpickr(time, {
    disableMobile: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i'
  })

  const formToJSON = elements =>
    [].reduce.call(
      elements,
      (data, element) => {
        if (element.hasAttribute('name')) data[element.name] = element.value
        return data
      },
      {}
    )

  const handleFormSubmit = e => {
    e.preventDefault()
    submitButton.disabled = true
    const formData = formToJSON(form.elements)

    jQuery.request('onSendMail', {
      url: '/ajax.html',
      data: formData,
      success: function() {
        renderSnackbar('Message has been sent successfully!')
        form.reset()
      },
      error: function (error) {
        renderSnackbar((error.responseJSON && error.responseJSON.message) || 'Something went wrong.', true)
      },
      complete: function () {
        submitButton.disabled = false
      }
    })
  }

  form.addEventListener('submit', handleFormSubmit)
}

export default function initContact() {
  initForm()
}
