import THEME from '../js/theme'

let snackbar = null
let snackbarTimer = null
let snackbarRemoveTimer = null
const snackbarHideDuration = 5000

const createSnackbar = (message, isError) => {
  snackbar = document.createElement('div')
  snackbar.className = `snackbar ${isError ? 'snackbar_error ' : ''}show-snackbar`
  snackbar.innerHTML = message
}

const removeSnackbarWithAnimation = () => {
  snackbar.classList.add('hide-snackbar')
  snackbarRemoveTimer = setTimeout(() => {
    snackbar.remove()
  }, THEME.DURATION.MEDIUM)
}

const checkSnackbarRender = () => {
  if (!document.body.contains(snackbar)) return
  clearTimeout(snackbarTimer)
  clearTimeout(snackbarRemoveTimer)
  snackbar.remove()
}

export default function renderSnackbar(message, isError) {
  checkSnackbarRender()
  createSnackbar(message, isError)
  document.body.append(snackbar)
  snackbarTimer = setTimeout(() => {
    removeSnackbarWithAnimation()
  }, snackbarHideDuration)
}
