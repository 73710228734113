const initDrawer = () => {
  let isDrawerOpen = false

  const drawer = document.querySelector('.header__menu-container')
  const drawerList = drawer.querySelector('ul')
  const hamburger = document.querySelector('.hamburger')

  drawerList.addEventListener('click', (e) => {
    if (e.target.tagName !== 'A') return
    drawer && closeDrawer()
  })

  const openDrawer = () => {
    hamburger.classList.add('header__hamburger_active')
    drawer.classList.add('header__menu-container--opened')
    isDrawerOpen = true
  }

  const closeDrawer = () => {
    hamburger.classList.remove('header__hamburger_active')
    drawer.classList.remove('header__menu-container--opened')
    isDrawerOpen = false
  }

  hamburger.addEventListener(
    'click',
    () => (isDrawerOpen ? closeDrawer() : openDrawer())
  )
}

export default function initHeader() {
  initDrawer()
}
